import { initializeChakraComponent } from 'Shared/chakra-initialize';
import { StyledPagination, PaginationProps } from '@ifixit/pagination';

function getPageLink(page: number) {
   if (page === 1) {
      return window.location.href.replace(/\/\d+(?=(?:\?|#|$))/, '');
   }

   // Regular expression to match the last number in the path
   const lastNumberRegex = /\/\d+(?=(?:\?|#|$))/;
   // Matches the query string or hash
   const urlParamsRegex = /((\?|#).*)/;
   const currentUrl = window.location.href;
   if (lastNumberRegex.test(currentUrl)) {
      return window.location.href.replace(lastNumberRegex, '/' + page.toString());
   } else if (urlParamsRegex.test(currentUrl)) {
      const strippedUrl = currentUrl.replace(urlParamsRegex, '');
      const params = currentUrl.match(urlParamsRegex)?.[0] ?? '';
      return `${strippedUrl}/${page}${params}`;
   } else {
      return window.location.href.endsWith('/') ? `${currentUrl}${page}` : `${currentUrl}/${page}`;
   }
}

export function AnswersPagination(props: PaginationProps) {
   const paginationProps = {
      ...props,
      getPageUrl: getPageLink,
   };

   return <StyledPagination {...paginationProps} />;
}

initializeChakraComponent('AnswersPaginationComponent', AnswersPagination);
