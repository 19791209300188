import { forwardRef, useEffect, useState } from 'react';
import type { Ref } from 'react';
import { HStack, useBreakpointValue } from '@chakra-ui/react';
import {
   faChevronLeft,
   faChevronRight,
   faChevronsLeft,
   faChevronsRight,
} from '@fortawesome/pro-solid-svg-icons';
import { FaIcon } from '@ifixit/icons';
import { Pagination as BasePagination, PaginationItem, PaginationLink } from './index';
import { usePagination, UsePaginationProps } from './usePagination';

// eslint-disable-next-line react/display-name
export const StyledPagination = forwardRef(
   (props: UsePaginationProps, ref: Ref<HTMLDivElement>) => {
      const { currentPage, numberOfPages } = usePagination(props);
      const responsiveVisibleNumberOfPages = useBreakpointValue({
         base: 3,
         sm: 5,
         lg: 7,
      });
      const [visibleNumberOfPages, setVisibleNumberOfPages] = useState(
         props.visibleNumberOfPages ?? 3
      );

      useEffect(() => {
         if (responsiveVisibleNumberOfPages && !props.visibleNumberOfPages) {
            setVisibleNumberOfPages(responsiveVisibleNumberOfPages);
         }
      }, [responsiveVisibleNumberOfPages]);

      // eslint-disable-next-line @typescript-eslint/no-unused-vars, unicorn/no-useless-undefined
      const getPageUrl = props.getPageUrl ? props.getPageUrl : (page: number) => undefined;

      return (
         <HStack justify="center" borderTopWidth="1px" ref={ref}>
            <BasePagination
               numberOfPages={numberOfPages}
               page={currentPage}
               onChange={page => props.onChange?.(page)}
               size={{ base: 'sm', md: 'md' }}
               visibleNumberOfPages={visibleNumberOfPages}
            >
               {pagination => (
                  <>
                     <PaginationItem>
                        <PaginationLink
                           as="a"
                           aria-label="Go to first page"
                           page="first"
                           href={getPageUrl(1)}
                           icon={() => <FaIcon icon={faChevronsLeft} h="3" color="gray.500" />}
                           onClick={event => {
                              if (!props.onChange) {
                                 return;
                              }
                              event.preventDefault();
                              pagination.first();
                           }}
                        />
                     </PaginationItem>
                     <PaginationItem>
                        <PaginationLink
                           as="a"
                           aria-label="Go to previous page"
                           page="previous"
                           href={getPageUrl(currentPage - 1)}
                           icon={() => <FaIcon icon={faChevronLeft} h="3" color="gray.500" />}
                           onClick={event => {
                              if (!props.onChange) {
                                 return;
                              }
                              event.preventDefault();
                              pagination.previous();
                           }}
                        />
                     </PaginationItem>
                     {pagination.pages.map(page => (
                        <PaginationItem key={page}>
                           <PaginationLink
                              as="a"
                              aria-label={
                                 pagination.currentPage === page
                                    ? 'current page'
                                    : `go to page ${page}`
                              }
                              page={page}
                              href={getPageUrl(page)}
                              onClick={event => {
                                 if (!props.onChange) {
                                    return;
                                 }
                                 event.preventDefault();
                                 pagination.goto(page);
                              }}
                              fontSize="sm"
                              fontWeight="medium"
                           />
                        </PaginationItem>
                     ))}
                     <PaginationItem>
                        <PaginationLink
                           as="a"
                           aria-label="Go to next page"
                           data-testid="next-page"
                           page="next"
                           href={getPageUrl(currentPage + 1)}
                           icon={() => <FaIcon icon={faChevronRight} h="3" color="gray.500" />}
                           onClick={event => {
                              if (!props.onChange) {
                                 return;
                              }
                              event.preventDefault();
                              pagination.next();
                           }}
                        />
                     </PaginationItem>
                     <PaginationItem>
                        <PaginationLink
                           as="a"
                           aria-label="Go to last page"
                           page="last"
                           href={getPageUrl(numberOfPages)}
                           icon={() => <FaIcon icon={faChevronsRight} h="3" color="gray.500" />}
                           onClick={event => {
                              if (!props.onChange) {
                                 return;
                              }
                              event.preventDefault();
                              pagination.last();
                           }}
                        />
                     </PaginationItem>
                  </>
               )}
            </BasePagination>
         </HStack>
      );
   }
);
